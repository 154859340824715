@import '~bootstrap/scss/bootstrap';
@import './styles/styles';
@import './components/components';
body {
	font-family: $font-text;
	margin: 0;
	padding: 0;
	color: map-get($theme-colors, 'text');
	background-color: map-get($theme-colors, 'bkg');
	box-sizing: border-box;
	overflow: hidden;
}
nav {
	ul {
		margin: 0;
		padding: 0;
		li {
			list-style-type: none;
			a {
				display: block;
			}
		}
	}
}
main {
	padding: $module;
}
img {
	display: block;
	width: 100%;
}
small {
	font-weight: $text-light;
	@include font-line-height(10px);
	text-align: center;
	display: block;
}
figure {
	margin: 0;
}
.app-container {
	max-width: 1400px;
	padding-left: 0;
	padding-right: 0;
	margin: 0 auto;
	@include breakpoint(xl) {
		max-width: 1140px;
	}
	@include breakpoint(lg) {
		max-width: 960px;
	}
	@include breakpoint(md) {
		max-width: 700px;
	}
	@include breakpoint(sm-down) {
		padding-left: 15px;
		padding-right: 15px;
		max-width: 540px;
	}
}
