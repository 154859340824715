.login-container {
	background-color: map-get($theme-colors, 'white');
	border-radius: 10px;
	height: 92vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	figure {
		width: 200px;
		height: 100px;
		overflow: hidden;
		position: relative;
		margin-top: calc(#{$module} * 3);
		img {
			position: absolute;
			top: 0;
			left: 0;
			object-fit: contain;
			object-position: center;
			width: 100%;
			height: 100%;
		}
	}
	.login {
		width: 350px;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		.my-btn {
			margin: calc(#{$module} / 2) auto;
		}
	}
	.copyright {
		margin-top: 48px;
	}
}

.login-style {
	padding: 12px;
}
