#dash-profile {
	display: flex;
	align-items: center;
	position: absolute;
	top: 24px;
	right: 24px;
	padding: 0 calc(#{$module} / 2) calc(#{$module} / 3);
	cursor: pointer;
	.name-circle {
		text-transform: uppercase;
		margin-right: 8px;
		font-weight: 500;
		font-size: 13px;
		line-height: 32px;
		text-align: center;
		width: 32px;
		height: 32px;
		color: $light-blue;
		border-radius: 256px;
		background: linear-gradient(0deg, rgba(0, 133, 255, 0.1), rgba(0, 133, 255, 0.1)), linear-gradient(0deg, #ffffff, #ffffff);
	}
	h5 {
		margin-right: 16px;
	}
	svg {
		cursor: pointer;
	}
	.close-panel {
		padding: calc(#{$module} / 2);
		cursor: pointer;
		position: absolute;
		top: 40px;
		border-top: $light-blue solid 1px;
		right: 0;
		background-color: white;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		li {
			list-style-type: none;
			display: flex;
			justify-content: flex-end;
			padding: 8px 0;
			a {
				display: flex;
				color: inherit;
				text-decoration: none;
			}
			&:hover {
				color: $light-blue;
				a {
					color: $light-blue;
				}
			}
		}
		svg {
			margin-right: 4px;
		}
	}
}
