.line-graph {
	margin-top: 24px;
	height: 80px;
	margin-bottom: 24px;
	p {
		font-weight: 700;
		font-size: 16px;
		line-height: 24px;
		color: #1c1c1c;
	}
}
