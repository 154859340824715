.dropdown {
	position: absolute;
	right: 24px;
	top: 120px;
	background: #ffffff;
	border: 1px solid #e8e8e8;
	box-sizing: border-box;
	// box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
	border-radius: 8px;
	min-width: 155px;
	&:first-child {
		margin-right: calc(#{$module} / 4);
	}
	@include breakpoint(sm-down) {
		max-width: 100%;
		width: 100%;
		margin: calc(#{$module} / 3) 0;
		&:first-child {
			margin-right: 0;
		}
	}

	.caption {
		position: relative;
		z-index: 3;
		display: flex;
		align-items: center;
		justify-content: space-between;
		cursor: pointer;
		padding: calc(#{$module} / 6) calc(#{$module} / 3);

		&:hover {
			border-color: map-get($theme-colors, 'third');
		}
		p {
			@include font-line-height($font-xs);
			text-align: right;
			color: map-get($theme-colors, 'dark-grey');
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			display: block;
			max-width: 97%;
		}

		svg {
			margin-right: calc(#{$module} / 4);
			color: #585757;
			font-size: 14px;
			&.arrow {
				margin-right: 0;
				margin-left: calc(#{$module} / 6);
				font-size: 10px;
			}
		}

		@include breakpoint(sm-down) {
			width: 100%;
			padding: calc(#{$module} / 2) calc(#{$module} / 2);
			text-align: center;
		}
	}
	.list {
		position: absolute;
		background-color: map-get($theme-colors, 'white');
		width: 100%;
		border: 1px solid #e8e8e8;
		border-top: none;
		display: none;
		border-radius: 8px;
		right: -1px;
		overflow: scroll;
		max-height: 300px;

		.item {
			padding: calc(#{$module} / 4) calc(#{$module} / 2);
			cursor: pointer;
			text-align: right;
			color: map-get($theme-colors, 'dark-grey');
			@include font-line-height($font-xs);

			@include breakpoint(sm-down) {
				text-align: right;
			}
			&.selected {
				color: map-get($theme-colors, 'secondary');
			}
			&:hover {
				background-color: map-get($theme-colors, 'third');
				color: map-get($theme-colors, 'white');
			}
		}
	}
	&.open {
		.list {
			display: block;
			z-index: 4;
		}
	}
}
