.swiper-container {
	.swiper-wrapper {
		.swiper-slide {
			min-height: 100%;
			height: auto;
			img {
				height: 100%;
				object-fit: cover;
			}
		}
	}
}
