// Fonts
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

$font-titles: 'Inter', sans-serif !important;
$font-text: 'Inter', sans-serif !important;

//Shadows
$shadow: 0px 3px 8px rgba(97, 97, 97, 0.25);

//Border Radius
$radius: (calc(#{$module} / 4));

$font-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-xl: ($font-base * 1.5) !default;
$font-lg: ($font-base * 1.25) !default;
$font-md: ($font-base * 1.125) !default;
$font-sm: ($font-base * 0.9) !default;
$font-xs: ($font-base * 0.75) !default;

$text-light: 300 !default;
$text-normal: 400 !default;
$text-semibold: 700 !default;
$text-bold: 700 !default;

$titles-light: 300 !default;
$titles-normal: 400 !default;
$titles-semibold: 500 !default;
$titles-bold: 700 !default;

$text-base: $text-normal !default;
$line-height-base: 1.5 !default;

$h1-size: $font-base * 3 !default;
$h2-size: $font-base * 2.5 !default;
$h3-size: $font-base * 1.75 !default;
$h4-size: $font-base * 1.35 !default;
$h5-size: $font-base * 1.25 !default;
$h6-size: $font-base !default;

$headings-font-family: inherit !default;
$headings-font-weight: 400 !default;
$headings-line-height: 1.2 !default;
$headings-color: inherit !default;
