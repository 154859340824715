#sport-chart {
	width: calc((100% / 2) - calc(#{$module} * 0.5));
	margin-top: $module;
	position: relative;
	margin-right: $module;
	.client-list-link {
		position: absolute;
		top: 0;
		right: $module;
	}
	.fix {
		right: 0;
		position: relative;
		height: 400px;
		margin-top: calc(#{$module} / 2);

		svg {
			height: 400px !important;
		}
		.final-count {
			position: absolute;
			bottom: 100px;
			right: 0;
			background: #ffffff;
			padding: 8px;
			box-shadow: 0px 11px 15px rgba(0, 0, 0, 0.1), 0px 9px 46px rgba(0, 0, 0, 0.06), 0px 24px 38px rgba(0, 0, 0, 0.04);
			border-radius: 8px;
			min-width: 130px;
			.result {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}
			.p3-13 {
				display: flex;
				align-items: center;
				svg {
					height: 14px !important;
					margin-right: 6px;
					color: $primary;
					path {
						stroke: $primary !important;
					}
				}
			}
		}
	}

	.top {
		display: flex;
		justify-content: space-between;
		margin-top: $module;
		padding-bottom: calc(#{$module} / 2);
		border-bottom: 1px solid map-get($theme-colors, 'grey');
		margin-top: calc(#{$module} * (-2.5));

		.data {
			display: flex;
			flex-direction: column;
			width: 50%;
			align-items: flex-start;
			small {
				color: #969696;
			}
		}
	}
	.bottom {
		display: flex;
		margin-top: calc(#{$module} / 2);
		justify-content: space-between;
		flex-grow: 1;
		align-items: stretch;
		position: relative;
		.sports-table {
			width: 100%;
			max-height: 300px;
			overflow-x: scroll;

			.sport-row {
				display: flex;
				justify-content: space-between;
				padding: 6px;
				border-bottom: 1px solid map-get($theme-colors, 'dark');
				&:hover {
					background-color: map-get($theme-colors, 'dark');
				}
			}
		}
	}
}
