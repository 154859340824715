.state-badge {
	border-radius: 6px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 2px 6px;
	width: fit-content;
	min-width: 64px;
	span {
		text-align: center;
		font-weight: 500;
		font-size: 13px;
		line-height: 20px;
		display: block;
		color: map-get($theme-colors, 'text');
	}
	&.activo {
		background: $bkg-green;
	}
	&.trial {
		background: $light-blue;
		span {
			color: white;
		}
	}
	&.bajac {
		background: #ff99e6;
	}
	&.bajanc {
		background: #cc80cc;
		span {
			color: white;
		}
	}
	&.baja {
		background: #ff1a66;
	}
	&.baja {
		background: #e64d66;
		span {
			color: white;
		}
	}
	&.se {
		background: #f7f7f7;
	}
}
