.points-badge {
	border-radius: 6px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 2px 6px;
	width: fit-content;
	span {
		text-align: center;
		font-weight: 700;
		font-size: 12px;
		line-height: 14px;
		display: block;
		color: white;
	}
	&.a {
		background-color: #00ba34;
	}
	&.b {
		background-color: #f98600;
	}
	&.c {
		background-color: #e92c2c;
	}
}
