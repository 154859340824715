@import './ModalInfo/ModalInfo.scss';
#contact-info {
	width: calc((100% / 2) - calc(#{$module} * 0.5));
	margin-bottom: $module;
	position: relative;
	.state-badge {
		position: absolute;
		top: 24px;
		right: 24px;
	}
	.client-list-link {
		position: absolute;
		bottom: $module;
		right: $module;
		margin-top: 0;
	}
	.content {
		display: flex;
		align-items: baseline;
		padding: $module 0 42px;
		.left,
		.right {
			width: 50%;
		}
	}
}
