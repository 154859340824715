#client-chart {
	width: 100%;
	.dropdown {
		top: 24px;
		right: 24px;
	}
	.fix {
		display: flex;
		margin-top: 48px;
		.left {
			width: calc((100% / 12) * 10);
			padding-right: 40px;
			height: 500px;
			svg {
				height: 500px !important;
			}
		}
		.right {
			padding-left: 40px;
			padding-right: 24px;
			width: calc((100% / 12) * 2);
		}
		.data {
			.addition {
				margin-bottom: $module;
				display: flex;
				flex-direction: column;
				div {
					display: flex;
					small {
						color: #969696;
						margin-right: calc(#{$module} / 3);
					}
				}
			}
		}
	}
}
