.my-modals {
	display: flex;
	.modal-content {
		width: 100%;
		max-height: 80vh;
	}
	.modal-header {
		display: flex;
		flex-direction: column;
	}
	.modal-body {
		overflow: scroll;
	}
	.modal-footer {
		display: flex;
		justify-content: space-between;
	}
	.modal-width {
		width: 70%;
		max-width: none !important;
	}
}
.modal-backdrop.show {
	background-color: map-get($theme-colors, 'dark-primary');
	backdrop-filter: blur(10px);
}
