.isactive-badge {
	border-radius: 6px;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 2px 6px;
	width: fit-content;
	span {
		font-weight: 500;
		font-size: 13px;
		line-height: 20px;
		display: block;
	}
	svg {
		margin-right: 4px;
		width: 17px;
		height: 17px;
	}
	&.active {
		background: $bkg-green;
		span,
		svg {
			color: map-get($theme-colors, 'light-green-base');
		}
	}
	&.desactive {
		background: $bkg-red;
		span,
		svg {
			color: map-get($theme-colors, 'light-red-base');
		}
	}
}
