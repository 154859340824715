.back-btn {
	display: flex;
	align-items: center;
	position: absolute;
	top: 24px;
	color: #585757;
	text-decoration: none;
	z-index: 10;
	span {
		margin-left: calc(#{$module} / 2);
	}
}
