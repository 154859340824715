.dash-nav {
	display: flex;
	left: 0;
	flex-direction: column;
	// justify-content: space-between;
	padding: $module;
	background-color: map-get($theme-colors, 'white');
	z-index: 3;
	min-width: 296px;
	height: 95vh;
	min-height: 100vh;
	overflow: scroll;
	> * {
		text-decoration: none;
		color: inherit;
	}
	.buttons-nav {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		width: 100%;
		padding-top: $module;
		> * {
			width: 49%;
			margin-bottom: 4px;
		}
	}
	.exit {
		justify-self: flex-end;
		align-self: center;
		flex-grow: 1;
	}
	.company-logo {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		width: 100%;
		padding-left: 10px;
		figure {
			height: 56px;
			width: 56px;
			border-radius: 5px;
			border: 1px solid #e8e8e8;
			overflow: hidden;
			img {
				height: 100%;
				object-fit: contain;
				object-position: center;
			}
		}
		div {
			flex-grow: 1;
			margin-left: 16px;
		}
	}
	ul {
		padding: 0;
		margin: 0;
		margin-top: $module;

		.menu-title {
			margin-bottom: 8px;
			display: block;
			padding-left: 10px;
		}
		li {
			list-style-type: none;
			padding: 10px;
			border-radius: 10px;
			a {
				display: flex;
				align-items: center;
				color: #585757;
				text-decoration: none;
				span {
					flex-grow: 1;
					margin-left: calc(#{$module} / 2);
				}
			}
			&:hover,
			&.active {
				background: linear-gradient(0deg, rgba(0, 133, 255, 0.1), rgba(0, 133, 255, 0.1)), linear-gradient(0deg, #ffffff, #ffffff);
				a {
					color: map-get($theme-colors, 'text');
					svg {
						color: $light-blue;
					}
				}
			}
		}
	}
}
