#pagination {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-top: 24px;
	button {
		margin-left: calc(#{$module} / 3);
		padding: 10px 12px;
		border: 1px solid #e8e8e8;
		box-sizing: border-box;
		box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
		border-radius: 8px;
		cursor: pointer;
		background: #ffffff;
		svg {
			font-size: 10px;
			line-height: 0;
			color: #969696;
		}
		&.disabled {
			cursor: not-allowed;
			border: 1px solid #e9e9e9;
			box-shadow: none;
			svg {
				color: #e9e9e9;
			}
		}
	}
}
