.list-elm {
	display: flex;
	align-items: baseline;
	margin-bottom: calc(#{$module} / 2);
	.icon {
		margin-right: calc(#{$module} / 3);
		svg {
			width: 24px;
			height: 24px;
			color: #bbb;
		}
	}
	.info {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		.first {
			font-weight: 600;
			font-size: 16px;
			line-height: 24px;
			color: #1c1c1c;
		}
		.second {
			font-weight: 400;
			font-size: 16px;
			line-height: 24px;
			color: #1c1c1c;
			&.link {
				color: #1c1c1c;
				cursor: pointer;
				text-decoration: none;
				&:hover {
					color: map-get($theme-colors, 'primary');
				}
			}
		}
	}
}
