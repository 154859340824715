.sport-structure-table {
	.top {
		margin-top: $module;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		.each-top {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			margin-right: calc(#{$module} * 2);
			small {
				color: map-get($theme-colors, 'text-secondary');
			}
		}
	}
}
.drops {
	display: flex;
	justify-content: flex-end;
	.dropdown {
		position: relative;
		top: 0 !important;
		right: 0 !important;
	}
}
