$break-xxs-down: 375px;
$break-xs-up: 376px;
$break-xs-down: 575px;
$break-sm-up: 576px;
$break-sm-down: 767px;
$break-md-up: 768px;
$break-md-down: 991px;
$break-lg-up: 992px;
$break-lg-down: 1199px;
$break-xl-up: 1200px;
$break-xl-down: 1499px;
$break-xxl: 1500px;

@mixin breakpoint($point) {
	@if $point == xxs {
		@media (max-width: $break-xxs-down) {
			@content;
		}
	}
	@if $point == xs-up {
		@media (min-width: $break-xs-up) {
			@content;
		}
	} @else if $point == xs {
		@media (min-width: $break-xs-up) and (max-width: $break-xs-down) {
			@content;
		}
	} @else if $point == xs-down {
		@media (max-width: $break-xs-down) {
			@content;
		}
	}
	@if $point == sm-up {
		@media (min-width: $break-sm-up) {
			@content;
		}
	} @else if $point == sm {
		@media (min-width: $break-sm-up) and (max-width: $break-sm-down) {
			@content;
		}
	} @else if $point == sm-down {
		@media (max-width: $break-sm-down) {
			@content;
		}
	} @else if $point == md-up {
		@media (min-width: $break-md-up) {
			@content;
		}
	} @else if $point == md {
		@media (min-width: $break-md-up) and (max-width: $break-md-down) {
			@content;
		}
	} @else if $point == md-down {
		@media (max-width: $break-md-down) {
			@content;
		}
	} @else if $point == lg-up {
		@media (min-width: $break-lg-up) {
			@content;
		}
	} @else if $point == lg {
		@media (min-width: $break-lg-up) and (max-width: $break-lg-down) {
			@content;
		}
	} @else if $point == lg-down {
		@media (max-width: $break-lg-down) {
			@content;
		}
	} @else if $point == xl-up {
		@media (min-width: $break-xl-up) {
			@content;
		}
	} @else if $point == xl {
		@media (min-width: $break-xl-up) and (max-width: $break-xl-down) {
			@content;
		}
	} @else if $point == xxl {
		@media (min-width: $break-xxl) {
			@content;
		}
	}
}
