#country-chart {
	width: calc((100% / 2) - calc(#{$module} * 0.5));
	margin-top: $module;
	overflow-x: hidden;
	position: relative;
	.client-list-link {
		position: absolute;
		right: $module;
		top: $module;
		z-index: 2;
		margin-top: 0;
	}
	.bottom {
		display: flex;
		flex-direction: column-reverse;
		margin-top: calc(#{$module} / 2);
		justify-content: space-between;
		flex-grow: 1;
		align-items: stretch;
		position: relative;
		.country-table {
			width: 100%;
			max-height: 400px;
			overflow-x: scroll;
			margin-top: calc(#{$module} * (-3));
			.country-row {
				display: flex;
				justify-content: space-between;
				padding: 6px;
				border-bottom: 1px solid map-get($theme-colors, 'dark');
				&:hover {
					background-color: map-get($theme-colors, 'dark');
				}
			}
		}
		.fix {
			right: 0;
			position: relative;
			margin-top: calc(#{$module} / 2);
			height: 400px;
			svg {
				height: 400px !important;
			}
			.final-count {
				position: absolute;
				bottom: 100px;
				right: 0;
				background: #ffffff;
				padding: 8px;
				box-shadow: 0px 11px 15px rgba(0, 0, 0, 0.1), 0px 9px 46px rgba(0, 0, 0, 0.06), 0px 24px 38px rgba(0, 0, 0, 0.04);
				border-radius: 8px;
				min-width: 130px;
				.result {
					display: flex;
					align-items: center;
					justify-content: space-between;
				}
				.p3-13 {
					display: flex;
					align-items: center;
					svg {
						height: 14px !important;
						margin-right: 6px;
						color: $primary;
						path {
							stroke: $primary !important;
						}
					}
				}
			}
		}
	}
}
