.search-bar {
	align-items: center;
	position: relative;
	margin: $module 0 calc(#{$module} / 4);
	display: flex;
	input {
		background-color: map-get($theme-colors, 'mid-grey');
		border: 1px solid #e5e5e5;
		padding: 0 32px 0 16px;
		outline: none;
		height: 32px;
		// padding: 0 calc(#{$module} * 1);
		font-size: 10px;
		width: 100%;
		caret-color: map-get($theme-colors, 'third');
		z-index: 11;
		position: relative;
		margin-bottom: 0;
		&::placeholder {
			font-size: 12px;
		}
	}
	.icon {
		color: map-get($theme-colors, 'grey');
		position: absolute;
		right: 10px;
		top: 33%;
		font-size: 12px;
		z-index: 12;
		cursor: pointer;
		@include breakpoint(lg) {
			right: -130px;
		}
		&:hover {
			color: map-get($theme-colors, 'third');
		}
	}
	.search-results {
		border: 1px solid #e5e5e5;
		display: none;
		width: -webkit-fill-available;
		height: auto;
		background-color: white;
		padding: calc(#{$module} / 3);
		position: absolute;
		top: 32px;
		left: 0;
		z-index: 10;
		border-radius: 5px;
		max-height: 150px;
		overflow: scroll;
		&.open {
			display: block;
		}
		.club {
			display: flex;
			align-items: center;
			text-decoration: none;
			padding: calc(#{$module} / 6) 0;
			.avatar {
				width: 28px;
				height: 28px;
				overflow: hidden;
				margin: 0;
				margin-right: 8px;
				position: relative;
				img {
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0;
					object-fit: contain;
					object-position: center;
				}
			}
		}
	}
}
