#club-admon,
#club-competition,
#club-com {
	width: 100%;
	margin-bottom: $module;
	position: relative;

	.view {
		display: flex;
		flex-direction: column;
		.graphs {
			padding: 24px 0 48px;
			display: flex;
			.list {
				display: flex;
				flex-wrap: wrap;
				margin-top: 6px;
				.each {
					margin-right: 12px;
					margin-bottom: 6px;
					display: flex;
					strong {
						margin-right: 3px;
					}
					svg {
						position: static;
						margin-top: 0;
					}
				}
			}
			.left,
			.right {
				height: 200px;
				width: 50%;
				position: relative;
				svg {
					left: 0px;
					margin-top: 24px;
					top: 24px;
				}
			}
		}
		.right {
			padding-left: 24px;
			border-left: 1px solid #e8e8e8;
		}
	}
}
