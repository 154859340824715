#funnel {
	width: calc((100% / 12) * 10);
	padding: calc(#{$module} * 1) 0;
	padding-right: 40px;
	margin-top: calc(#{$module} * 2);
	.data {
		padding: calc(#{$module} * 1) 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.each {
			display: flex;
			flex-direction: column;
			align-items: center;
		}
		.icon-fix {
			padding: 0 24px;
		}
	}
}
