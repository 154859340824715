.dashboard-in {
	display: flex;
	#main-screen {
		padding: $module;
		flex-grow: 1;
		position: relative;
		overflow: scroll;
		height: 100vh;
	}
}
