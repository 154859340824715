.rdt_TableHeader {
	display: none !important;
}
.rdt_TableHeadRow {
	.rdt_TableCol {
		.rdt_TableCol_Sortable {
			div {
				color: #969696;
			}
		}
	}
}
.rdt_TableCell {
	svg {
		color: #969696;
		font-size: 30px;
	}
}
