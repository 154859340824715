@import './ActiveBadge/ActiveBadge.scss';
@import './BackButton/BackButton.scss';
@import './Box/Box.scss';
@import './DashNav/DashNav.scss';
@import './DashProfile/DashProfile.scss';
@import './MainTitle/MainTitle.scss';
@import './Badge/Badge.scss';
@import './Pagination/Pagination.scss';
@import './DropDown/DropDown.scss';
@import './Acclaration/Acclaration.scss';
@import './StateBadge/StateBadge.scss';
@import './ClubSearch/ClubSearch.scss';
@import './ListElm/ListElm.scss';
@import './PointsBadge/PointsBadge.scss';
@import './Alert/Alert.scss';
@import './LineGraph/LineGraph.scss';

.export-csv {
	margin-right: calc(#{$module} / 3);
	cursor: pointer;
	&:hover {
		color: map-get($theme-colors, 'primary');
	}
}
