.box-content {
	box-shadow: 0px 2px 1px 0px #0000000f;
	position: relative;
	border-radius: 8px;
	padding: $module;
	background-color: white;
	h4 {
		position: relative;
		display: flex;
		align-items: center;
		.points-badge {
			margin-left: calc(#{$module} / 3);
		}
	}
	.box.p4-14 {
		color: #969696;
	}
	.chevron {
		margin-left: auto;
		cursor: pointer;
		width: 12px;
	}
	.preview {
		padding: 12px 0;
		display: flex;
		.each {
			margin-right: calc(#{$module} * 2);
		}
	}

	.view {
		display: none !important;
	}
	&.open {
		.view {
			display: flex !important;
		}
	}
}
