#main-title {
	margin: $module 0;
	padding-top: $module;
	width: 100%;
}
#main-title-club {
	width: 100%;
	margin: $module 0;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding-top: $module;
	.h2 {
		text-decoration: none;
		color: #1c1c1c;
		&:hover {
			color: map-get($theme-colors, 'primary');
		}
	}
	.avatar {
		width: 64px;
		height: 64px;
		overflow: hidden;
		margin: 0;
		margin-right: 8px;
		position: relative;
		img {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			object-fit: contain;
			object-position: center;
		}
	}
	.points {
		color: #00ba34;
		font-weight: 500;
		font-size: 36px;
		line-height: 48px;
		padding: 0px 24px;
		border: 1px solid #12a23a;
		border-radius: 8px;
		margin-left: calc(#{$module} / 3);
	}
}
