h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $font-text;
	margin: 0;
}

h1 {
	font-style: normal;
	font-weight: 500;
	font-size: 48px;
	line-height: 60px;
}
h2,
.h2 {
	font-style: normal;
	font-weight: 500;
	font-size: 36px;
	line-height: 48px;
}
h3 {
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 32px;
}
h4 {
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 28px;
	letter-spacing: 0em;
	text-align: left;
}
h6 {
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 20px;
	&.h6-14-b {
		font-size: 14px;
		font-weight: 600;
	}
}
h5 {
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	&.h5-16-b {
		font-weight: 700;
	}
}
p {
	margin: 0;
	color: map-get($theme-colors, 'text-secondary');
}
.p1-20 {
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 28px;
}
.p2-16 {
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 0em;
	text-align: left;
}
.p3-13 {
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 20px;
}
.p4-14 {
	font-style: normal;
	font-weight: 300;
	font-size: 14px;
	line-height: 17px;
	color: #1c1c1c;
	display: flex;
	align-items: center;
	svg {
		color: #aaa;
		margin: 0 4px 0 2px;
		height: 14px;
	}
}
.caps1-14sb {
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.04em;
	text-transform: uppercase;
}
.caps1-14r {
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.04em;
	text-transform: uppercase;
}
.caps2-12sb {
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 0.04em;
	text-transform: uppercase;
}
.caps2-12r {
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 0.04em;
	text-transform: uppercase;
	color: #969696;
}
.caps3-10sb {
	font-style: normal;
	font-weight: 600;
	font-size: 10px;
	line-height: 16px;
	letter-spacing: 0.04em;
	text-transform: uppercase;
}
.caps3-10r {
	font-style: normal;
	font-weight: normal;
	font-size: 10px;
	line-height: 16px;
	letter-spacing: 0.04em;
	text-transform: uppercase;
}
