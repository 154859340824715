#club-payments {
	width: calc((100% / 2) - calc(#{$module} * 0.5));
	margin-bottom: $module;
	position: relative;
	.content {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		padding: $module 0 0;
		height: 100%;
		.top {
			width: 100%;
			display: flex;
			justify-content: flex-start;
			padding-bottom: calc(#{$module} / 2);
			border-bottom: 1px solid map-get($theme-colors, 'grey');
			.data {
				display: flex;
				flex-direction: column;
				width: 50%;
				align-items: flex-start;
				small {
					color: #969696;
				}
			}
		}
		.bottom {
			width: 100%;
			display: flex;
			justify-content: space-between;
			padding: $module 0;
			.left {
				p {
					font-weight: 400;
					font-size: 16px;
					line-height: 24px;
					display: flex;
					align-items: center;
					color: #1c1c1c;
					strong {
						margin-right: 4px;
					}
					span.color {
						margin-right: 8px;
						display: block;
						width: 18px;
						height: 18px;
						border-radius: 4px;
						&.red {
							background: #d63734;
						}
						&.green {
							background: #12a23a;
						}
					}
				}
			}
			svg {
				height: 150px !important;
			}
		}
	}
}
