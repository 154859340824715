$primary: #fe9003;
$secondary: #1a1e2a;

$light-blue: #0085ff;

$bkg-green: linear-gradient(0deg, rgba(0, 186, 52, 0.1), rgba(0, 186, 52, 0.1)), #ffffff;
$bkg-red: linear-gradient(0deg, rgba(255, 59, 59, 0.1), rgba(255, 59, 59, 0.1)), #ffffff;

$theme-colors: (
	'primary': $primary,
	'secondary': $secondary,
	'third': $secondary,
	'dark': #f7f7f7,
	'grey': #666666,
	'medium-grey': #f2f2f2,
	'light-grey': #fafafa,
	'white': #fff,
	'black': #000,
	'bkg': #f7f7f7,
	'btn-main': $secondary,
	'btn-secondary': $primary,
	'btn-third': $primary,
	'btn-danger': $secondary,
	'text': #1c1c1c,
	'text-secondary': #969696,
	'light-green-base': #00ba34,
	'light-red-base': #e92c2c,
);
