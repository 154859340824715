@import './Clientes/Clientes.scss';
@import './Countries/Countries.scss';

#dash-comercial,
.grid {
	display: flex;
	flex-wrap: wrap;
	overflow: scroll;
	padding-bottom: 96px;
	justify-content: space-between;
}
