.alert {
	width: 100%;
	padding: 15px;
	color: #a94442;
	font-weight: 600;
	font-size: 14px;
	line-height: 21px;
	text-align: center;
	letter-spacing: 0.15px;
	background: #f2dede;
	border-radius: 5px;
	margin-top: calc(#{$module} / 2);
}
