.badge {
	border-radius: 6px;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 2px 6px;
	width: fit-content;
	span {
		font-weight: 500;
		font-size: 13px;
		line-height: 20px;
		display: block;
	}
	svg {
		margin-right: 4px;
		width: 17px;
		height: 17px;
	}
	&.up {
		background: linear-gradient(0deg, rgba(0, 186, 52, 0.1), rgba(0, 186, 52, 0.1)), #ffffff;
		span,
		svg {
			color: #00ba34;
		}
	}
	&.down {
		background: linear-gradient(0deg, rgba(255, 59, 59, 0.1), rgba(255, 59, 59, 0.1)), #ffffff;
		span,
		svg {
			color: #e92c2c;
		}
	}
}
