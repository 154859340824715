@import './Club/Clubinfo/ClubInfo.scss';
@import './Club/ClubPayments/ClubPayments.scss';
@import './Club/ClubAdmon/ClubAdmon.scss';
@import './Club/ClubCom/ClubCom.scss';
@import './Club/ClubCompetition/ClubCompetition.scss';
@import './Club/ClubStore/ClubStore.scss';
@import './Club/ClubTrainings/ClubTrainings.scss';

#clientes {
	padding-bottom: 48px;
	position: relative;
	#pagination {
		margin-bottom: 24px;
	}
}
.rdt_Pagination {
	select {
		margin-bottom: 0;
		height: 18px;
		padding: 0;
		height: 20px;
		line-height: 20px;
		width: fit-content;
		margin-left: 24px;
	}
}
.hWujZN {
	width: 120px;
}
