.modal-info {
	.content {
		.each-data {
			padding: $module 0 0;
			.icon-title {
				display: flex;
				align-items: center;
				svg {
					margin-right: calc(#{$module} / 3);
					width: 20px;
					height: 20px;
					color: #bbb;
				}
			}
			.info {
				padding: 12px 0;
				display: flex;
				align-items: baseline;
				.left,
				.right {
					width: 50%;
					display: flex;
					align-items: flex-start;
					flex-direction: column;
					> * {
						margin-bottom: 12px;
					}
					p {
						font-weight: 400;
						font-size: 14px;
						line-height: 24px;
						color: #1c1c1c;
					}
				}
			}
		}
	}
}
