*[class^='my-btn'] {
	// max-width: 200px;
	&:focus,
	&:active {
		outline: 0;
		box-shadow: none;
	}
}
.client-list-link {
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 20px;
	color: map-get($theme-colors, 'text');
	text-decoration: none;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: fit-content;
	background: #ffffff;
	border: 1px solid #e8e8e8;
	box-sizing: border-box;
	padding: 4px 8px;
	box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
	border-radius: 8px;
	margin-top: $module;
	svg {
		margin-left: calc(#{$module} / 2);
	}
}

.my-btn {
	font-weight: $text-semibold;
	font-size: $font-sm;
	background-color: map-get($theme-colors, 'primary');
	border: none;
	color: map-get($theme-colors, 'white');
	border-radius: 5px;
	padding: $module;
	text-transform: uppercase;
	&.outlined {
		border: 1px solid map-get($theme-colors, 'primary');
		background-color: white;
		color: map-get($theme-colors, 'primary');
	}
	&:hover {
		color: map-get($theme-colors, 'white');
		text-decoration: none;
		background-color: map-get($theme-colors, 'primary');
	}
	&.secondary {
		background-color: map-get($theme-colors, 'btn-secondary');
		&:hover {
			background-color: map-get($theme-colors, 'primary');
		}
	}
	&.third {
		background-color: map-get($theme-colors, 'btn-third');
		&:hover {
			background-color: map-get($theme-colors, 'primary');
		}
	}
	&.danger {
		background-color: map-get($theme-colors, 'btn-danger');
		&:hover {
			background-color: map-get($theme-colors, 'primary');
		}
	}
	&.mini {
		font-size: 10px;
		padding: calc(#{$module} / 4) calc(#{$module} / 3);
	}
	&.underlined {
		font-size: 10px;
		background-color: transparent;
		color: map-get($theme-colors, 'primary');
		padding: 0;
		border: none;
	}
}

.my-btn-back {
	color: map-get($theme-colors, 'primary');
	text-transform: uppercase;
	font-size: $font-xs;
}

.my-btn-more {
	color: map-get($theme-colors, 'buttons');
	text-transform: uppercase;
	font-size: $font-xs;
}
